.layout {
  z-index: 1;
  width: 100%;
}

.layout .header {
  display: flex;
  align-items: center;
  padding: 0px 30px 0px 20px;
  height: fit-content;
  background: var(--white, #FFF);
  box-shadow: 0px 4px 4px 0px #EBF3FF;
  z-index: 9999;
}

.layout .content {
  padding: 23px 25px 25px 25px;
  background: var(--Ligh-Gray-BG, #F3F3F3);
  margin-top: 60px;
}

app-header {
  align-items: center;
  justify-content: space-between;
}

.layout .footer {
  text-align: center;
  margin-top: auto;
  padding: 20px;
  box-shadow: 0px -2px 2px gray;
}

.sidebar {
  color: #7d84ab;
  overflow-x: hidden !important;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.sidebar::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

.sidebar:hover::-webkit-scrollbar-thumb {
  background-color: #CC257A;
}

.sidebar::-webkit-scrollbar {
  width: 6px;
  background-color: #CC257A;
}

.sidebar-content {
  padding: 10px 0;
  height: 100vh;
  /* max-height: calc(100vh - 280px); */
  max-height: calc(100vh - 115px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.sidebar-content > * {
  -webkit-overflow-scrolling: touch;
}

.sidebar-content::-webkit-scrollbar-thumb {
  border-radius: 4px;
}

.sidebar-content:hover::-webkit-scrollbar-thumb {
  background-color: #CC257A;
}

.sidebar-content::-webkit-scrollbar {
  width: 6px;
  background-color: #0c1e35;
}

.sidebar .image-wrapper {
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: none;
}

.sidebar .image-wrapper > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.sidebar.has-bg-image .image-wrapper {
  display: block;
}

.sidebar .sidebar-layout {
  height: auto;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  /* position: fixed; */
  background-color: #0C0C0C;
  z-index: 99;
  width: inherit;
  min-width: 110px !important;
}

.sidebar .sidebar-layout .sidebar-header {
  height: 100px;
  min-height: 115px;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid rgba(222, 222, 222, 0.2);
  min-width: 280px;
}

.sidebar .sidebar-layout .sidebar-header > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.sidebar .sidebar-layout .sidebar-content {
  flex-grow: 1;
}

.sidebar .sidebar-layout .sidebar-footer {
  height: 145px;
  min-height: 145px;

}

.sidebar .sidebar-layout .sidebar-footer > span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@keyframes swing {

  0%,
  30%,
  50%,
  70%,
  100% {
    transform: rotate(0deg);
  }

  10% {
    transform: rotate(10deg);
  }

  40% {
    transform: rotate(-10deg);
  }

  60% {
    transform: rotate(5deg);
  }

  80% {
    transform: rotate(-5deg);
  }
}

.layout .header {
  -webkit-transition: width, 0.3s;
  transition: width, 0.3s;
}

.layout .sidebar {
  -webkit-transition: width, left, right, 0.3s;
  transition: width, left, right, 0.3s;
}

.layout .sidebar.collapsed {
  width: 100px;
  min-width: 110px !important;
}

.layout .sidebar.collapsed .sidebar-collapser {
  left: 77px !important;
}

.layout .sidebar .menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.layout .sidebar .menu .menu-header {
  font-weight: 600;
  padding: 10px 25px;
  font-size: 0.8em;
  letter-spacing: 2px;
  transition: opacity 0.3s;
  opacity: 0.5;
}

.layout .sidebar .menu .menu-item a {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 0 20px;
  color: #878787;
}

.layout .sidebar .menu .menu-item a:hover {
  background: #CC257A;
  color: #fff;
  transition: all 0.2s;
}

.layout .sidebar .menu .menu-item a:hover .menu-icon img {
  filter: invert(100%);
  mix-blend-mode: plus-lighter;
}

.layout .sidebar .menu .menu-item.logout-menu a:hover .menu-icon img {
  filter: invert(50%);
}

/* .layout .sidebar .menu .menu-item.active a{
  background: #CC257A;
  color: #fff;
} */

/* .layout .sidebar .menu .menu-item.active a .menu-icon img{
  filter: invert(100%) ;
  mix-blend-mode: plus-lighter;
} */

.layout .sidebar .menu .menu-item a.active {
  background: #CC257A;
  color: #fff;
}

.layout .sidebar .menu .menu-item a.active .menu-icon img {
  filter: invert(100%);
  mix-blend-mode: plus-lighter;
}

.layout .sidebar .menu .menu-item a .menu-icon {
  font-size: 1.2rem;
  width: 35px;
  min-width: 35px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  display: inline-block;
  margin-right: 10px;
  border-radius: 2px;
  transition: color 0.3s;

}

.layout .sidebar .menu .menu-item a .menu-icon i {
  display: inline-block;
}

.layout .sidebar .menu .menu-item a .menu-title {
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex-grow: 1;
  transition: color 0.3s;
}

.layout .sidebar .menu .menu-item a .menu-prefix,
.layout .sidebar .menu .menu-item a .menu-suffix {
  display: inline-block;
  padding: 5px;
  opacity: 1;
  transition: opacity 0.3s;
}

.layout .sidebar .menu .menu-item a:hover .menu-title {
  color: #dee2ec;
}

.layout .sidebar .menu .menu-item a:hover .menu-icon {
  color: #dee2ec;
}

.layout .sidebar .menu .menu-item a:hover .menu-icon i {
  animation: swing ease-in-out 0.5s 1 alternate;
}

.layout .sidebar .menu .menu-item a:hover::after {
  border-color: #dee2ec !important;
}

.layout .sidebar .menu .menu-item.sub-menu {
  position: relative;
}

.layout .sidebar .menu .menu-item.sub-menu > a::after {
  content: "";
  transition: transform 0.3s;
  border-right: 2px solid currentcolor;
  border-bottom: 2px solid currentcolor;
  width: 5px;
  height: 5px;
  transform: rotate(-45deg);
}

.layout .sidebar .menu .menu-item.sub-menu > .sub-menu-list {
  padding-left: 20px;
  display: none;
  overflow: hidden;
  z-index: 999;
}

.layout .sidebar .menu .menu-item.sub-menu.open > a {
  color: #dee2ec;
}

.layout .sidebar .menu .menu-item.sub-menu.open > a::after {
  transform: rotate(45deg);
}

/*  .layout .sidebar .menu .menu-item.active>a .menu-title {
  color: #dee2ec;
} */

/* .layout .sidebar .menu .menu-item.active>a::after {
  border-color: #dee2ec;
} */

/* .layout .sidebar .menu .menu-item.active>a .menu-icon {
  color: #dee2ec;
} */

.layout .sidebar .menu > ul > .sub-menu > .sub-menu-list {
  background-color: #0b1a2c;
}

.layout .sidebar .menu.icon-shape-circle .menu-item a .menu-icon,
.layout .sidebar .menu.icon-shape-rounded .menu-item a .menu-icon,
.layout .sidebar .menu.icon-shape-square .menu-item a .menu-icon {
  background-color: #0b1a2c;
}

.layout .sidebar .menu.icon-shape-circle .menu-item a .menu-icon {
  border-radius: 50%;
}

.layout .sidebar .menu.icon-shape-rounded .menu-item a .menu-icon {
  border-radius: 4px;
}

.layout .sidebar .menu.icon-shape-square .menu-item a .menu-icon {
  border-radius: 0;
}

.layout .sidebar:not(.collapsed) .menu > ul > .menu-item.sub-menu > .sub-menu-list {
  visibility: visible !important;
  position: static !important;
  transform: translate(0, 0) !important;
}

.layout .sidebar.collapsed .menu > ul > .menu-header {
  opacity: 0;
}

.layout .sidebar.collapsed .menu > ul > .menu-item > a .menu-prefix,
.layout .sidebar.collapsed .menu > ul > .menu-item > a .menu-suffix {
  opacity: 0;
}

.layout .sidebar.collapsed .menu > ul > .menu-item.sub-menu > a::after {
  content: "";
  width: 5px;
  height: 5px;
  background-color: currentcolor;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  right: 10px;
  top: 50%;
  border: none;
  transform: translateY(-50%);
}

.layout .sidebar.collapsed .menu > ul > .menu-item.sub-menu > a:hover::after {
  background-color: #dee2ec;
}

.layout .sidebar.collapsed .menu > ul > .menu-item.sub-menu > .sub-menu-list {
  transition: none !important;
  width: 200px;
  margin-left: 3px !important;
  border-radius: 4px;
  display: block !important;
}

.layout .sidebar.collapsed .menu > ul > .menu-item.active > a::after {
  background-color: #dee2ec;
}

.layout .sidebar.has-bg-image .menu.icon-shape-circle .menu-item a .menu-icon,
.layout .sidebar.has-bg-image .menu.icon-shape-rounded .menu-item a .menu-icon,
.layout .sidebar.has-bg-image .menu.icon-shape-square .menu-item a .menu-icon {
  background-color: rgba(11, 26, 44, 0.6);
}

.layout .sidebar.has-bg-image:not(.collapsed) .menu > ul > .sub-menu > .sub-menu-list {
  background-color: rgba(11, 26, 44, 0.6);
}

.layout.rtl .sidebar .menu .menu-item a .menu-icon {
  margin-left: 10px;
  margin-right: 0;
}

.layout.rtl .sidebar .menu .menu-item.sub-menu > a::after {
  transform: rotate(135deg);
}

.layout.rtl .sidebar .menu .menu-item.sub-menu > .sub-menu-list {
  padding-left: 0;
  padding-right: 20px;
}

.layout.rtl .sidebar .menu .menu-item.sub-menu.open > a::after {
  transform: rotate(45deg);
}

.layout.rtl .sidebar.collapsed .menu > ul > .menu-item.sub-menu a::after {
  right: auto;
  left: 10px;
}

.layout.rtl .sidebar.collapsed .menu > ul > .menu-item.sub-menu > .sub-menu-list {
  margin-left: -3px !important;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  /* height: 100vh;
  min-height: 100vh;
  -webkit-min-logical-height: 100dvh;
  -webkit-min-logical-height: 100lvh;
  -webkit-min-logical-height: 100svh; */
  font-family: "Poppins", sans-serif;
  color: #3f4750;
  font-size: 0.9rem;
}

a {
  text-decoration: none;
}

@media (max-width: 992px) {
  #btn-collapse {
    display: none;
  }
}

.layout .sidebar .pro-sidebar-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.layout .sidebar .pro-sidebar-logo > div {
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  font-size: 24px;
  font-weight: 700;
  background-color: #ff8100;
  margin-right: 10px;
}

.layout .sidebar .pro-sidebar-logo > h5 {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 20px;
  line-height: 30px;
  transition: opacity 0.3s;
  opacity: 1;
}

.layout .sidebar .footer-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 0.8em;
  padding: 20px 0;
  border-radius: 8px;
  width: 180px;
  min-width: 190px;
  margin: 0 auto;
  background-color: #162d4a;
}

.layout .sidebar .footer-box img.react-logo {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.layout .sidebar .footer-box a {
  color: #fff;
  font-weight: 600;
  margin-bottom: 10px;
}

.layout .sidebar .sidebar-collapser {
  transition: left, right, 0.3s;
  position: fixed;
  left: 247px;
  top: 17px;
  width: 30px;
  height: 30px;
  background-color: #00829f;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2em;
  transform: translateX(50%);
  z-index: 999;
  cursor: pointer;
  color: white;
  /* box-shadow: 1px 1px 4px #0c1e35; */
  border-radius: 8px;
  background: #A1A1AA;
}

.layout .sidebar.collapsed .pro-sidebar-logo > h5 {
  opacity: 0;
}

.layout .sidebar.collapsed .footer-box {
  display: none;
}

.layout .sidebar.collapsed .sidebar-collapser {
  left: 67px;
}

.layout .sidebar.collapsed .sidebar-collapser i {
  transform: rotate(180deg);
}

.badge {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  color: #fff;
  background-color: #6c757d;
}

.badge.primary {
  background-color: #ab2dff;
}

.badge.secondary {
  background-color: #079b0b;
}

.sidebar-toggler {
  /* position: fixed; */
  /* right: 20px; */
  /* top: 20px; */
}

.social-links a {
  margin: 0 10px;
  color: #3f4750;
}

.layout .sidebar.collapsed .menu .menu-item a {
  flex-direction: column;
  height: auto;
  padding: 8px 10px;
}

.layout .sidebar.collapsed .menu .menu-item a .menu-title {
  overflow: auto;
  white-space: wrap;
  font-size: 0.8rem;
  text-align: center;

}

.layout .sidebar.collapsed .menu .menu-item a .menu-icon {
  margin-right: 0px;
}


.layout.has-sidebar .layout {
  width: calc(100% - 280px);
}

.layout.has-sidebar.sidebar-off .layout {
  width: calc(100% - 100px);
}

.layout.has-sidebar.sidebar-off .layout .header {
  width: calc(100% - 100px);
}

/* .layout.fixed-sidebar .sidebar {
  height: 100%;
  overflow: auto;
} */
/* .layout .page-content .card{
  height: calc(100vh - 175px);
  overflow-y: auto;
} */
/* width */
.layout .page-content .card::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.layout .page-content .card::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.layout .page-content .card::-webkit-scrollbar-thumb {
  background: #8b96a5;
}

/* Handle on hover */
.layout .page-content .card::-webkit-scrollbar-thumb:hover {
  background: #8b96a5;
}

@media (max-width: 992px) {
  body {
    background: #f3f3f3;
  }

  /* .layout {
    display: block;
  } */
  .layout .content {
    height: 100%;
  }

  .layout.has-sidebar .layout,
  .layout .header {
    width: 100%
  }

  .header .dropdown-toggle > span {
    display: none;
  }
}