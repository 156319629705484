.layout {
  min-height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1; }
  .layout.has-sidebar {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
  .layout .header {
    -webkit-transition: width, 1s;
    transition: width, 1s;
    height: 64px;
    min-height: 64px;
    position: fixed;
    width: calc(100% - 280px);
    z-index: 9;
   }
  .layout .sidebar {
   max-width: 280px;
   width: 280px;
    -webkit-transition: width, left, right, 1s;
    transition: width, left, right, 1s; }
    .layout .sidebar.collapsed {
      width: 100px;
      min-width: 100px; }
    @media (max-width: 480px) {
      .layout .sidebar.break-point-xs {
        position: fixed;
        left: -280px;
        height: 100%;
        top: 0;
        z-index: 100; }
        .layout .sidebar.break-point-xs.collapsed {
          left: -80px; }
        .layout .sidebar.break-point-xs.toggled {
          left: 0; }
          .layout .sidebar.break-point-xs.toggled ~ .overlay {
            display: block; }
        .layout .sidebar.break-point-xs ~ .layout .header {
          width: 100% !important;
          -webkit-transition: none;
          transition: none; } }
    @media (max-width: 576px) {
      .layout .sidebar.break-point-sm {
        position: fixed;
        left: -280px;
        height: 100%;
        top: 0;
        z-index: 100; }
        .layout .sidebar.break-point-sm.collapsed {
          left: -80px; }
        .layout .sidebar.break-point-sm.toggled {
          left: 0; }
          .layout .sidebar.break-point-sm.toggled ~ .overlay {
            display: block; }
        .layout .sidebar.break-point-sm ~ .layout .header {
          width: 100% !important;
          -webkit-transition: none;
          transition: none; } }
    @media (max-width: 768px) {
      .layout .sidebar.break-point-md {
        position: fixed;
        left: -280px;
        height: 100%;
        top: 0;
        z-index: 100; }
        .layout .sidebar.break-point-md.collapsed {
          left: -80px; }
        .layout .sidebar.break-point-md.toggled {
          left: 0; }
          .layout .sidebar.break-point-md.toggled ~ .overlay {
            display: block; }
        .layout .sidebar.break-point-md ~ .layout .header {
          width: 100% !important;
          -webkit-transition: none;
          transition: none; } }
    @media (max-width: 992px) {
      .layout .sidebar.break-point-lg {
        position: fixed;
        left: -280px;
        height: 100%;
        top: 0;
        z-index: 100; }
        .layout .sidebar.break-point-lg.collapsed {
          left: -80px; }
        .layout .sidebar.break-point-lg.toggled {
          left: 0; }
          .layout .sidebar.break-point-lg.toggled ~ .overlay {
            display: block; }
        .layout .sidebar.break-point-lg ~ .layout .header {
          width: 100% !important;
          -webkit-transition: none;
          transition: none; } }
    @media (max-width: 1200px) {
      .layout .sidebar.break-point-xl {
        position: fixed;
        left: -280px;
        height: 100%;
        top: 0;
        z-index: 100; }
        .layout .sidebar.break-point-xl.collapsed {
          left: -80px; }
        .layout .sidebar.break-point-xl.toggled {
          left: 0; }
          .layout .sidebar.break-point-xl.toggled ~ .overlay {
            display: block; }
        .layout .sidebar.break-point-xl ~ .layout .header {
          width: 100% !important;
          -webkit-transition: none;
          transition: none; } }
    @media (max-width: 1600px) {
      .layout .sidebar.break-point-xxl {
        position: fixed;
        left: -280px;
        height: 100%;
        top: 0;
        z-index: 100; }
        .layout .sidebar.break-point-xxl.collapsed {
          left: -80px; }
        .layout .sidebar.break-point-xxl.toggled {
          left: 0; }
          .layout .sidebar.break-point-xxl.toggled ~ .overlay {
            display: block; }
        .layout .sidebar.break-point-xxl ~ .layout .header {
          width: 100% !important;
          -webkit-transition: none;
          transition: none; } }
  .layout .footer {
    height: 64px;
    min-height: 64px; }
  .layout .content {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; }
  .layout .overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 99;
    display: none; }
  .layout .sidebar-toggler {
    display: none; }
    @media (max-width: 480px) {
      .layout .sidebar-toggler.break-point-xs {
        display: initial; } }
    @media (max-width: 576px) {
      .layout .sidebar-toggler.break-point-sm {
        display: initial; } }
    @media (max-width: 768px) {
      .layout .sidebar-toggler.break-point-md {
        display: initial; } }
    @media (max-width: 992px) {
      .layout .sidebar-toggler.break-point-lg {
        display: initial; } }
    @media (max-width: 1200px) {
      .layout .sidebar-toggler.break-point-xl {
        display: initial; } }
    @media (max-width: 1600px) {
      .layout .sidebar-toggler.break-point-xxl {
        display: initial; } }
  .layout.fixed-sidebar {
    }
    .layout.fixed-sidebar .sidebar {
      height: 100%;
      overflow: auto; }
      .layout.fixed-sidebar .sidebar ~ .layout {
        height: 100%;
        overflow: auto; }
  .layout.fixed-header .header {
    position: fixed;
    width: 100%;
    z-index: 2; }
    .layout.fixed-header .header ~ .layout,
    .layout.fixed-header .header ~ .content {
      margin-top: 64px; }
  .layout.fixed-header.fixed-sidebar .header {
    width: calc(100% - 280px); }
  .layout.fixed-header.fixed-sidebar .sidebar.collapsed ~ .layout .header {
    width: calc(100% - 80px); }
  .layout.rtl {
    direction: rtl; }
    @media (max-width: 480px) {
      .layout.rtl .sidebar.break-point-xs {
        left: auto;
        right: -280px; }
        .layout.rtl .sidebar.break-point-xs.collapsed {
          left: auto;
          right: -80px; }
        .layout.rtl .sidebar.break-point-xs.toggled {
          left: auto;
          right: 0; } }
    @media (max-width: 576px) {
      .layout.rtl .sidebar.break-point-sm {
        left: auto;
        right: -280px; }
        .layout.rtl .sidebar.break-point-sm.collapsed {
          left: auto;
          right: -80px; }
        .layout.rtl .sidebar.break-point-sm.toggled {
          left: auto;
          right: 0; } }
    @media (max-width: 768px) {
      .layout.rtl .sidebar.break-point-md {
        left: auto;
        right: -280px; }
        .layout.rtl .sidebar.break-point-md.collapsed {
          left: auto;
          right: -80px; }
        .layout.rtl .sidebar.break-point-md.toggled {
          left: auto;
          right: 0; } }
    @media (max-width: 992px) {
      .layout.rtl .sidebar.break-point-lg {
        left: auto;
        right: -280px; }
        .layout.rtl .sidebar.break-point-lg.collapsed {
          left: auto;
          right: -80px; }
        .layout.rtl .sidebar.break-point-lg.toggled {
          left: auto;
          right: 0; } }
    @media (max-width: 1200px) {
      .layout.rtl .sidebar.break-point-xl {
        left: auto;
        right: -280px; }
        .layout.rtl .sidebar.break-point-xl.collapsed {
          left: auto;
          right: -80px; }
        .layout.rtl .sidebar.break-point-xl.toggled {
          left: auto;
          right: 0; } }
    @media (max-width: 1600px) {
      .layout.rtl .sidebar.break-point-xxl {
        left: auto;
        right: -280px; }
        .layout.rtl .sidebar.break-point-xxl.collapsed {
          left: auto;
          right: -80px; }
        .layout.rtl .sidebar.break-point-xxl.toggled {
          left: auto;
          right: 0; } }
