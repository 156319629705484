/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import "bootstrap/scss/bootstrap";

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;500;600;700;800;900&display=swap");

:root {
    --mat-option-label-text-font: "Poppins", sans-serif !important;
    --mat-select-trigger-text-font: "Poppins", sans-serif !important;
}

$breakpoints: (
    "xs": 0,
    "sm": 575px,
    "md": 767px,
    "lg": 991px,
    "xl": 1200px,
);

@mixin xs {
    @media (min-width: map-get($breakpoints, "xs")) {
        @content;
    }
}

@mixin sm {
    @media (max-width: map-get($breakpoints, "sm")) {
        @content;
    }
}

@mixin md {
    @media (max-width: map-get($breakpoints, "md")) {
        @content;
    }
}

@mixin lg {
    @media (max-width: map-get($breakpoints, "lg")) {
        @content;
    }
}

@mixin xl {
    @media (max-width: map-get($breakpoints, "xl")) {
        @content;
    }
}

@mixin breakpoint($bp: 0) {
    @media (max-width: $bp) {
        @content;
    }
}

html,
body {
    width: 100%;
    overflow-x: hidden;
    font-family: 'Poppins', sans-serif !important;
}

body {
    font-family: 'Poppins', sans-serif !important;
    width: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}


p {
    font-size: 16px;
}

a {
    cursor: pointer;
    color: #CC257A
}

.textcolor {
    color: #CC257A
}



/* width */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #cc257a;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #cc257a;
}

:root {
    --mat-datepicker-calendar-date-selected-state-background-color: #CC257A;
    --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(204, 37, 122, 0.4);
    --mat-datepicker-calendar-date-focus-state-background-color: rgba(204, 37, 122, 0.3);
    --mat-datepicker-calendar-date-hover-state-background-color: rgba(204, 37, 122, 0.3);
    --mat-datepicker-toggle-active-state-icon-color: #CC257A !important;
    --mat-datepicker-calendar-date-in-range-state-background-color: rgba(204, 37, 122, 0.2);
}

app-header {
    min-height: 50px;
    display: flex;
    flex-direction: row-reverse;
}

main::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

main::-webkit-scrollbar-thumb {
    background-color: darkgrey;
}

.text-danger {
    color: #fd3f3f !important;
}

.text-pink {
    color: #cc257a !important;
}

label {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 8px;
}

.form-control {
    border-radius: 2px;
    padding: 1rem 0.5rem;
    border: 1px solid var(--input-grey, #e4e4e7);
    background-color: #fff;
    color: var(--gray-400, #9fa6b2);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.form-control:focus {
    border-color: var(--input-grey, #e4e4e7);
    box-shadow: none;
}

input[type="password" i].form-control {
    padding-right: 60px;
}

.page-heading {
    color: var(--Primary, #0c0c0c);
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px;
}

.btn-primary {
    border-radius: 4px;
    background: #cc257a;
    border-color: #cc257a;
    color: #fff;
    min-width: 125px;
    padding: 10px 10px;
}

.btn-primary:disabled {
    background: #E08CB7;
    border-color: #E08CB7;
}

.btn-success {
    border-radius: 4px;
    background: #228851;
    border-color: #228851;
    color: #fff;
    min-width: 125px;
    padding: 10px 10px;
}

.btn-success:hover {
    border-radius: 4px;
    background: #fff;
    border-color: #228851;
    color: #228851;
    min-width: 125px;
    padding: 10px 10px;
}

.btn-danger {
    border-radius: 4px;
    background: #cc2525;
    border-color: #cc252a;
    color: #fff;
    min-width: 125px;
    padding: 10px 10px;
}

.btn-danger:hover {
    border-radius: 4px;
    background: #fff;
    border-color: #cc252a;
    color: #cc252a;
    min-width: 125px;
    padding: 10px 10px;
}

.btn-primary:hover,
.btn-primary:active {
    border-radius: 4px;
    background: #fff;
    border-color: #cc257a;
    color: #cc257a;
    min-width: 125px;
    padding: 10px 10px;
}

.btn-check:checked+.btn,
:not(.btn-check)+.btn:active,
.btn:first-child:active,
.btn.active,
.btn.show {
    border-radius: 4px;
    background: #cc257a;
    border-color: #cc257a;
    color: #fff;
}

.btn-secondary {
    border-radius: 4px;
    background: #e4e4e7;
    border-color: #e4e4e7;
    color: #000;
    min-width: 125px;
    padding: 10px 10px;
}

.btn-info {
    border-radius: 4px;
    border: 1px solid #d4d4d8;
    color: #0c0c0c;
    min-width: 125px;
    padding: 10px 10px;
    background-color: transparent;
}

.btn-info:hover {
    border-radius: 4px;
    border: 1px solid #d4d4d8;
    color: #fff;
    min-width: 125px;
    padding: 10px 10px;
    background-color: #0c0c0c;
}

.btn-primary-outline {
    border: 1px solid #cc257a;
    background: #fff;
    border-radius: 4px;
    color: #cc257a;
    min-width: 125px;
    padding: 10px 10px;
}

.btn-primary-outline.previous-btn {
    color: #27272A;
    border: 1px solid #D4D4D8
}

.btn-primary-outline:hover,
.btn-primary-outline:focus {
    border: 1px solid #cc257a;
    background: #cc257a;
    border-radius: 4px;
    color: #fff;
    min-width: 125px;
    padding: 10px 10px;
}

.btn:focus-visible {
    color: #fff;
    background-color: #cc257a;
    border-color: #cc257a;
    outline: 0;
    box-shadow: none;
}

.rotate-icon {
    transform: rotate(180deg);
}

.search-icon {
    position: absolute;
    top: 15px;
    right: 18px;
}

.search-box {
    min-width: 300px;
    padding: 11.5px 35px 11.5px 10px;
    border-radius: 6px;
    width: auto;

    @media screen and (max-width: 767px) {
        min-width: 100px;
        width: calc(100vw - 290px);
    }
}

.heading-bottom {
    margin-bottom: 18px;

    @media screen and (max-width: 767px) {
        &>div {
            gap: 12px !important;
        }
    }
}

.card {
    border-radius: 0px;
}

.card-header h4 {
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.card-header {
    padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
    background-color: #fff;
}

.card-body:not(.mat-mdc-dialog-content .card-body):not(.setting-page.mat-mdc-dialog-content .card-body):not(.setting-page .card-body):not(.session-attendance.page-content .card-body):not(.my-account .card-body) {
    // height: 100vh;
    max-height: calc(100vh - 175px);
    overflow: auto;
}

.page-content .card>.card-body:not(.my-account.page-content .card > .card-body):not(.setting-page.page-content .card > .card-body):not(.session-attendance.page-content .card > .card-body) {
    padding: 0 !important;
}

.page-content .card>.card-body>.row:not(.my-account.page-content .card > .card-body > .row):not(.setting-page.page-content .card > .card-body > .row):not(.session-attendance.page-content .card > .card-body > .row) {
    margin: 0 !important;
}

.page-content .card>.card-body>.row>[class*="col-"]:not(.my-account.page-content .card > .card-body > .row > [class*="col-"]):not(.setting-page.page-content .card > .card-body > .row > [class*="col-"]):not(.session-attendance.page-content .card > .card-body > .row > [class*="col-"]) {
    padding: 0 !important;
}


.page-content .card-body .mat-custom-table>.table-responsive:not(.page-content .card-body .mat-custom-table > .table-responsive.no-data-available):not(.page-content .card-body .mat-custom-table > .table-responsive.table-style-2) {
    min-height: 100px;
    height: 100vh !important;
    max-height: calc(100vh - 245px) !important;
    padding: 0 15px !important;


    @media screen and (max-width:767px) {
        max-height: calc(100vh - 335px) !important;
    }
}

.page-content .card-body .mat-custom-table>.table-responsive.no-data-available {
    padding: 0 15px !important;
}

.table-responsive.table-style-2:not(.table-responsive.no-data-available) {
    min-height: 100px;
    height: 100vh !important;
    max-height: calc(100vh - 345px) !important;
    padding: 0 15px !important;

    @media screen and (max-width:767px) {
        max-height: calc(100vh - 365px) !important;
    }
}


.page-content .card-body .mat-custom-table>.table-responsive .mat-mdc-table thead .mat-mdc-header-cell {
    padding-top: 16px !important;
}

mat-paginator.mat-mdc-paginator {
    margin-bottom: 10px;
}

.number .mat-sort-header-container {
    justify-content: center;
}

/*Mat tabel*/
.mat-mdc-table {
    white-space: nowrap !important;
}

.mat-mdc-table thead {
    position: sticky;
    top: 0;
    background: #ffffff;
    z-index: 2;
}

.mat-mdc-header-cell,
.mat-mdc-cell,
.mat-mdc-paginator-container {
    font-family: "Poppins", sans-serif;
}

.image-rounded {
    border-radius: 100%;
}

.mdc-data-table__row:last-child .mat-mdc-cell {
    border-bottom: 1px solid var(--mat-table-row-item-outline-color, rgba(0, 0, 0, 0.12)) !important;
}

.mat-mdc-table .mat-mdc-header-cell {
    color: #a1a1aa;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.mat-mdc-table .mat-mdc-cell {
    color: #0c0c0c;
}

.mat-sort-header-content {
    white-space: nowrap;
}

/*Dialogue Design*/
h4.previewHeading {
    color: var(--black, #000);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0px;

    @media screen and (max-width: 480px) {
        font-size: 15px;
    }
}

mat-dialog-content {
    padding: 15px 20px;
}

.mat-dialogue-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;
    padding: 15px 20px;
}

.btn-close {
    border: 2px solid #fd3f3f;
    padding: 0px;
    text-align: center;
    border-radius: 100%;
    opacity: 1;
    width: 20px;
    min-width: 20px;
    height: 20px;
    background-size: contain;
    cursor: pointer;
    --bs-btn-close-bg: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='%23FD3F3F' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M8 8 L24 24 M8 24 L24 8'/%3E%3C/svg%3E");
}

.btn-close:focus {
    outline: 0;
    box-shadow: 2px 2px 3px #eee;
}

.dialogue-footer {
    display: flex;
    align-items: center;
    justify-content: end;
    flex-wrap: wrap;
    gap: 10px;
}

.mat-mdc-dialog-content {
    max-height: 82vh !important;
}

.confirmation {
    color: #f5141a;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px;
}

.Details {
    color: #f5141a;
    text-align: center;
    font-family: "Poppins", sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 29px;
}

.mat-mdc-dialog-actions {
    justify-content: center !important;
}

p.name {
    color: #667085;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 5px auto 0px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

/*Mat table paginator*/
.mat-mdc-paginator-container,
.mat-mdc-paginator-container {
    justify-content: space-between !important;
    flex-wrap: nowrap !important;

    @media screen and (max-width: 767px) {
        justify-content: center !important;
        flex-wrap: wrap !important;
        margin-top: 5px;
    }

    @media screen and (max-width: 480px) {
        .mat-mdc-paginator-range-actions {
            flex-wrap: wrap;
            justify-content: center;
        }

        .mat-mdc-paginator-range-label {
            margin: 10px 32px 0 24px;
            width: 100%;
            text-align: center;
        }
    }
}

.mat-mdc-form-field.mat-focused .mat-mdc-select-arrow {
    color: rgb(204 37 122) !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing {
    border-color: #cc257a !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: #cc257a !important;
}

.mat-mdc-paginator .mat-mdc-select-value {
    font-size: 14px !important;
}

.mat-mdc-paginator-page-size-label,
.mat-mdc-paginator-range-label {
    font-size: 14px !important;
}

.mat-mdc-paginator-container .mdc-text-field {
    border: 0px !important;
}

/*Mat Form Field*/
.mdc-text-field {
    border-radius: 0px !important;
    border: 1px solid var(--Field-Stock, #d0d5dd);
    background: #fff;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
    padding-top: 10px;
    padding-bottom: 10px;
}

.mat-mdc-form-field-infix {
    min-height: 42px;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
}

.mat-mdc-dialog-container .mdc-dialog__content,
.main-form,
.mat-mdc-select-value-text,
.mat-mdc-form-field-input-control.mat-mdc-form-field-input-control,
mat-expansion-panel {
    font-family: "Poppins", sans-serif !important;
}

.mat-mdc-form-field-error {
    font-size: 13px;
}

.mdc-line-ripple::before,
.mdc-line-ripple::after {
    content: none !important;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
    padding: 0 !important;
    font-family: "Poppins", sans-serif;
}

.mat-mdc-form-field-subscript-wrapper {
    height: 0px !important;
}

.mat-mdc-form-field-focus-overlay {
    background-color: rgb(255 248 252) !important;
}

mat-label {
    color: #0c0c0c;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 10px;
    display: block;
}

mat-label span {
    color: #fd3f3f;
}

.profile-image {
    width: 135px;
    height: 135px;
    border-radius: 50%;
    position: relative;
    object-fit: cover;
    background-color: #e4e4e7;
    border: 2px solid #e4e4e7;

    @media screen and (max-width: 767px) {
        width: 115px;
        height: 115px;
    }

    @media screen and (max-width: 480px) {
        width: 95px;
        height: 95px;
    }
}

.img-icon {
    position: absolute;
    bottom: 20px;
    right: 0px;
    cursor: pointer;
}

.upload-btn input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
}

.upload-btn {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.grey-box {
    background: #f3f3f3;
    padding: 15px 20px;
    width: 100%;
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.grey-box input[type="file"] {
    font-size: 100px;
    position: absolute;
    inset: 0 0 0 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
}

.upload-box {
    border: 3px solid var(--input-grey, #e4e4e7);
    background: var(--white, #fff);
    padding: 10px;
    margin: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 315px;
    gap: 15px;
    object-fit: cover;

    p {
        color: var(--Icon-Color, #98a2b3);
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.hidden {
    visibility: hidden;
    display: none;
}

.visible {
    visibility: visible;
}

/* The container */
.custom-checkbox {
    display: block;
    position: relative;
    padding-left: 20px;
    margin: 0 auto;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
    width: 20px;
    height: 20px;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

i {
    cursor: pointer;
}

/* coupon code style start*/
.mat-column-Coupon,
.mat-column-DiscountCoupon {
    min-width: 220px;
}

.coupon_main {
    background: #ee3331;
    min-height: 44px;
    border-radius: 3px;
    overflow: hidden;
    filter: drop-shadow(0 3px 5px rgba(0, 0, 0, 0.5));
    display: flex;
    align-items: stretch;
    justify-content: center;
    position: relative;
    text-transform: uppercase;
    -webkit-mask-image: radial-gradient(circle at 10px 50%, transparent 6px, #ee3331 5.5px);
    -webkit-mask-position: -10px;
    mask-image: radial-gradient(circle at 10px 50%, transparent 6px, #ee3331 5.5px);
    mask-position: -10px;
    width: auto;
    width: -webkit-fit-content;
    width: fit-content;
    min-width: 100%;
    gap: 7px;

    .left-part {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 20px;
        min-width: 130px;
    }

    .right-part {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 15px 0 10px;
        min-width: 100px;

        .percent {
            font-family: Poppins;
            color: #fff;
            margin-bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 1px;

            .value {
                font-size: 20px;
                font-weight: 700;
                line-height: 20px;
            }

            .off_value {
                font-size: 13px;
                font-style: italic;
                font-weight: 600;
                line-height: 13px;
                display: flex;
                flex-direction: column;
                flex-wrap: wrap;
                align-items: flex-start;
                gap: 2px;
            }
        }
    }

    .code {
        font-family: Poppins;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: center;
        color: #fff;
        width: 100%;
    }

    .coupon_border {
        border-right: 2px dotted #fff;
        position: relative;
        width: 1px;
    }

    .coupon_border::after,
    .coupon_border::before {
        content: "";
        position: absolute;
        width: 7px;
        height: 7px;
        background: #fff;
        border-radius: 100%;
    }

    .coupon_border::before {
        top: -4px;
        left: -3px;
    }

    .coupon_border::after {
        bottom: -4px;
        left: -3px;
    }
}

/* coupon code style end*/

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 100%;
    border: 1px solid #98a2b3;
}

/* On mouse-over, add a grey background color */
.custom-checkbox:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked~.checkmark {
    background-color: #cc257a;
    border: 1px solid #cc257a;
}

.custom-check input:checked {
    background-color: #cc257a;
    border: 1px solid #cc257a;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
    left: 7px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.custom-checkbox-container {
    display: flex;
    align-items: center;
}

.custom-checkbox-container .checkmark {
    margin-right: 5px; // Adjust the spacing as needed
}

.add-edit-event .mat-expansion-panel-header {
    min-height: 50px;
    height: auto !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
}

.profile-pic-table {
    max-width: 40px;
    height: 40px;
    border-radius: 100%;
    object-fit: cover;
    margin-right: 5px;
    border: 1px solid #e5e5e5;
}

/* width */
.mat-mdc-dialog-content::-webkit-scrollbar {
    width: 5px;
    border-radius: 10px;
}

/* Track */
.mat-mdc-dialog-content::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

/* Handle */
.mat-mdc-dialog-content::-webkit-scrollbar-thumb {
    background: #cc257a;
}

/* Handle on hover */
.mat-mdc-dialog-content::-webkit-scrollbar-thumb:hover {
    background: #cc257a;
}

/*Badge design*/
.mat-custom-table .badge {
    font-weight: 600;
    padding: 7px;
    font-size: 12px;
    border-radius: 0px;
}

.mat-header-row {
    position: sticky;
    top: 0;
    z-index: 100;
    background: white;
}

.removeclose-btn {
    width: 25px;
    min-width: auto;
    padding: 0px;
    position: absolute;
    right: 4px;
    top: 5px;
    border-radius: 100%;
    height: 25px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #cc2525;
    border-color: #cc252a;
    color: #fff;
    border: 1px solid;
    z-index: 999;
}

.delete-pic {
    position: absolute;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    border-radius: 0;
    padding: 5px;
    width: 30px;
    height: 30px;
    outline: none;
    border: none;
    visibility: hidden;
}

.grey-box .delete-pic {
    visibility: hidden;
}

.grey-box:hover .delete-pic {
    visibility: visible;
    z-index: 99;
}

.grey-box .img_box {
    position: relative;
}

.grey-box:hover .img_box:before {
    content: "";
    position: absolute;
    inset: 0 0 0 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 2;
    display: block;
    transition: all 0.5s;
}

.table-btn,
.table-btn:active {
    padding: 5px 10px !important;
}

.table-btn.primary {
    background: rgba(255, 219, 238, 1);
    border-color: rgba(255, 219, 238, 1);
    color: rgba(204, 37, 122, 1);
    padding: 2px 5px !important;
    min-width: 100px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
}

.table-btn.primary:hover,
.table-btn.primary:focus {
    border-radius: 4px;
    background: #cc257a;
    border-color: #cc257a;
    color: #fff;
}

html.cdk-global-scrollblock {
    left: unset !important;
    top: unset !important;
    overflow: hidden;
}

.status .btn {
    pointer-events: none;
}

.view_details_btn.btn {
    min-width: 85px;
    color: #000;
    background: #e4e4e7;
    border-color: #e4e4e7;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
}

// Wrap Data CSS
.WrapData {
    white-space: nowrap;
    max-width: 200px;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dialogue-content .WrapData {
    white-space: nowrap;
    max-width: 400px;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
}

.mat-cell:hover .WrapData {
    white-space: normal;
    overflow: visible;
    text-overflow: inherit;
    max-width: none;
}

.action_btn .btn-outline-success {
    padding: 3px 5px !important;
    min-width: 85px;
    color: #228851;
    background: #e4fff1;
    border-color: #e4fff1;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    border-radius: 4px;
}

.action_btn .btn-outline-success.selected,
.action_btn .btn-outline-success:hover,
.action_btn .btn-outline-success:focus {
    color: #fff;
    background: #228851;
    border-color: #228851;
}

.action_btn .btn-outline-danger {
    padding: 3px 5px !important;
    min-width: 85px;
    color: #f54e52;
    background: #ffdcdb;
    border-color: #ffdcdb;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    border-radius: 4px;
}

.action_btn .btn-outline-delete {
    padding: 3px 5px !important;
    min-width: 85px;
    color: white;
    background: red;
    border-color: #ffdcdb;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    border-radius: 4px;
}

.action_btn .btn-outline-danger.selected,
.action_btn .btn-outline-danger:hover,
.action_btn .btn-outline-danger:focus {
    color: #fff;
    background: #f54e52;
    border-color: #f54e52;
}

.action_btn .btn-outline-warning {
    padding: 3px 5px !important;
    min-width: 85px;
    color: #e7ad00;
    background: #f5eac9;
    border-color: #faecc1;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    border-radius: 4px;
}

.action_btn .btn-outline-warning.selected,
.action_btn .btn-outline-warning:hover,
.action_btn .btn-outline-warning:focus {
    color: #fff;
    background: #e7ad00;
    border-color: #e7ad00;
}

.session_event_btn {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
}

.session_event_btn .btn-success,
.session_event_btn .btn-danger {
    max-width: 30px;
    max-height: 30px;
    border-radius: 50% !important;
    padding: 10px;
    line-height: normal;
    min-width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

// Time Picker Design
.timepicker-overlay {
    z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
    z-index: 10000000 !important;
}

ngx-material-timepicker-container header.timepicker__header {
    background-color: #cc257a !important;
}

ngx-material-timepicker-container .clock-face__number>span.active {
    background-color: #cc257a !important;
}

ngx-material-timepicker-container .clock-face__clock-hand {
    background-color: #cc257a !important;
}

ngx-material-timepicker-container .timepicker-button {
    color: #cc257a !important;
}

.ck.ck-editor__top.ck-reset_all {
    z-index: var(--ck-z-modal);
    position: sticky;
    top: 0;
}

.mat-mdc-dialog-content .ck.ck-editor__top.ck-reset_all {
    z-index: var(--ck-z-modal);
    position: sticky;
    top: -21px;
}

.back-btn {
    min-width: auto !important;
    width: 35px;
    display: inline-flex;
    align-items: center;
    gap: 6px;
}

.normal .toast-container>div {
    background-position: 12px 20px !important;
}

// Styling for filter modal
.modal_filter_closebtn {
    top: 8px;
    right: 8px;
    position: absolute;
    padding: 0px;
    z-index: 9999;
}

.modal_filter_btn .dialogue-footer {
    justify-content: end !important;
    padding: 0px 24px 0px 24px;
}

.modal_filter_btns {
    position: absolute !important;
    max-width: 380px;
    display: block;
    max-height: 350px;
    right: 25px;
    top: 148px;
}

.modal_filter_btns .dialog-actions .clear_link {
    font-family: inter;
    font-size: 16px;
    color: #667085;
    font-weight: 700;
}

.modal_filter_btns .dialog-actions .apply_link {
    font-family: inter;
    font-size: 16px;
    font-weight: 700;
    color: #cc257a;
}

.modal_filter_btns .mdc-dialog__actions {
    min-height: 25px;
}

.modal_filter_btns .mdc-dialog .mdc-dialog__content {
    padding: 20px 24px 0px 24px;
    overflow: hidden;
}

@media only screen and (max-width: 1166px) {
    .modal_filter_btns {
        top: 200.5px;
    }
}

@media screen and (min-width: 767px) and (max-width: 1167px) {
    .heading-bottom .btn.btn-filter {
        min-width: unset;
    }

    .heading-bottom .btn.btn-filter span {
        display: none;
    }

    .heading-bottom .btn.btn-filter i {
        margin: 0 !important;
    }

    .heading-bottom .btn.btn-primary {
        min-width: unset;
    }

    .heading-bottom .btn.btn-primary span {
        display: none;
    }

    .heading-bottom .btn.btn-primary i {
        margin-right: 0 !important;
    }

    .modal_filter_btns {
        top: 147.5px;
    }
}

@media screen and (min-width: 719px) and (max-width: 767px) {
    .modal_filter_btns {
        top: 200.5px;
    }
}

@media screen and (max-width: 767px) {
    .modal_filter_btns {
        top: 200.5px;
    }

    .heading-bottom .btn.btn-filter {
        min-width: unset;
    }

    .heading-bottom .btn.btn-filter i {
        margin: 0 !important;
    }

    .heading-bottom .btn.btn-filter span {
        display: none;
    }

    .heading-bottom .btn.btn-primary {
        min-width: unset;
    }

    .heading-bottom .btn.btn-primary span {
        display: none;
    }

    .heading-bottom .btn.btn-primary i {
        margin-right: 0 !important;
    }
}

// Styling checkboxes whithin the userfilter
.option .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.option .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
    background: #CC257A;
    border-radius: 5px;
}

.option .mat-pseudo-checkbox-full {
    border: 0;
}

.option .mat-pseudo-checkbox {
    border-radius: 5px;
    background: #E3E3E3;
}

// Time picker icon
.session_timer .input_time {
    display: flex;
    align-items: center;
    position: relative;
}

.session_timer .input_time input {
    padding-right: 30px;
}

.session_timer .clock-icon-svg {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
}

// Wrapping text in event page
.page-heading.WrapData {
    max-width: 500px;
}

div.mat-mdc-select-panel {
    -webkit-overflow-scrolling: touch;
}

:ng-deep {
    @media screen and (max-width:480px) {
        .cdk-overlay-pane {
            max-width: 90vw !important;
        }
    }

}

.btn-green {
    border-radius: 4px;
    background: #43AA55;
    border-color: #43AA55;
    color: #fff;
    min-width: 125px;
    padding: 10px 10px;
}

.btn-green:hover,
.btn-green:active {
    border-radius: 4px;
    background: #fff;
    border-color: #43AA55;
    color: #43AA55;
    min-width: 125px;
    padding: 10px 10px;
}

.btn-pink {
    border-radius: 4px;
    background: #FF7878;
    border-color: #FF7878;
    color: #fff;
    min-width: 125px;
    padding: 10px 10px;
}

.btn-pink:hover,
.btn-pink:active {
    border-radius: 4px;
    background: #fff;
    border-color: #FF7878;
    color: #FF7878;
    min-width: 125px;
    padding: 10px 10px;
}

.btn-blue {
    border-radius: 4px;
    background: #70AAE3;
    border-color: #70AAE3;
    color: #fff;
    min-width: 125px;
    padding: 10px 10px;
}

.btn-blue:hover,
.btn-blue:active {
    border-radius: 4px;
    background: #fff;
    border-color: #70AAE3;
    color: #70AAE3;
    min-width: 125px;
    padding: 10px 10px;
}

.serch {
    justify-content: end;
}